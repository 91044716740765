@charset "UTF-8";
@font-face {
  font-family: 'Baskerville';
  src: url("../fonts/librebaskerville-regular-webfont.woff2") format("woff2"), url("../fonts/librebaskerville-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Baskerville';
  src: url("../fonts/librebaskerville-italic-webfont.woff2") format("woff2"), url("../fonts/librebaskerville-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

* {
  margin: 0;
  padding: 0;
  font-weight: normal; }

body {
  background: black;
  color: #ddd;
  font-family: 'Baskerville', serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
  margin: 60px 1.25em;
  font-size: 16px;
  line-height: 30px; }

a {
  color: #ddd;
  text-decoration: none;
  border-bottom: 1px solid whitesmoke; }
  a:hover {
    text-decoration: none;
    border: 0; }

::selection {
  background: greenyellow;
  color: black; }

.site-title {
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 0.2); }

.volume-header__title,
.single__title {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 50px;
  margin-bottom: 30px;
  text-align: center;
  letter-spacing: 6px;
  white-space: nowrap; }

p {
  margin-bottom: 30px; }

.menu__list {
  list-style: none;
  padding: 0;
  margin-bottom: 120px;
  text-align: center;
  line-height: 40px; }

.menu__item {
  display: inline-block;
  margin-right: 0.5em; }

.volume-header {
  margin-bottom: 60px;
  text-align: center; }

.volume-header__title {
  margin: 0;
  line-height: 30px; }

.download__list {
  display: block;
  text-align: center; }

.download__item {
  display: inline-block;
  margin-right: 0.5em; }

.download__link {
  color: rgba(255, 255, 255, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.2); }

.volume-content {
  margin-bottom: 50px; }

.stories {
  counter-reset: story; }

.story {
  counter-increment: story;
  display: block;
  text-align: center;
  margin-bottom: 40px; }

.story__link {
  display: inline-block;
  line-height: 1.5; }
  .story__link::before {
    content: counter(story, upper-roman) "";
    display: block;
    color: rgba(255, 255, 255, 0.2); }

.single::after {
  content: '♦︎';
  text-align: center;
  display: block;
  color: rgba(255, 255, 255, 0.2); }

.player {
  position: relative; }

.player__button {
  text-align: center;
  width: 100%; }
  .player__button label {
    display: block;
    font-size: 0;
    color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    line-height: 0; }
    .player__button label::before {
      display: block;
      content: 'Listen';
      font-size: 16px;
      line-height: 50px; }
    .player__button label:hover::before {
      border: 0; }
  .player__button input:checked + label::before {
    content: 'Stop'; }
  .player__button input {
    opacity: 0;
    position: absolute; }

.player__progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  top: 40px;
  background: rgba(255, 255, 255, 0.2);
  color: greenyellow; }

.player__progress[value]::-webkit-progress-bar {
  background: rgba(255, 255, 255, 0.2);
  background: transparent; }

.player__progress[value]::-moz-progress-bar {
  background: greenyellow; }

.player__progress[value]::-webkit-progress-value {
  background: greenyellow; }

@media (min-width: 600px) {
  body {
    font-size: 16px;
    line-height: 30px;
    max-width: 550px;
    margin: 60px auto; }
  .site-title {
    margin-bottom: 20px; }
  .menu__list {
    display: flex;
    justify-content: space-between; }
  .menu__item {
    margin-right: 0; }
  .volume-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end; }
  .volume-header__title {
    display: inline-block;
    margin: 0;
    line-height: 30px;
    letter-spacing: 15px; }
  .volume-header__title,
  .single__title {
    margin-bottom: 40px; }
  .volume-content {
    margin-bottom: 60px; }
  .download {
    display: inline-block; }
  .download__list {
    display: inline-block; }
  .download__item {
    margin-left: 0.5em; }
  .stories {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .story {
    flex-basis: 33%;
    margin-bottom: 60px; }
  .player__button {
    position: absolute;
    right: 0;
    top: 0;
    width: 70px; } }
